import theme from 'config/theme';

export default {
  wrapper: {
    width: '100%',
    height: '100%',
    padding: '0 20px',
    background: theme.palette.blues.lightestBlue,
  },
  innerWrapper: {
    margin: '0 auto',
    maxWidth: theme.customValues.maxBasicPageWidth,
  },
};
