import React from 'react';
import { Box } from '@mui/material';
import { Loader, NoAccess, PermissionsGate } from '@components';
import styles from './basicLayout.style';

const BasicLayout = ({ children, entity, isLoading }) => {
  return (
    <PermissionsGate entity={entity} RenderError={NoAccess}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.wrapper}>
          <Box sx={styles.innerWrapper}>
            <Box sx={styles.content}>{children}</Box>
          </Box>
        </Box>
      )}
    </PermissionsGate>
  );
};

export default BasicLayout;
